import React, { useState, useCallback, useEffect} from 'react';
import './styles/CreateTask.css';
import axios from "axios";
import Modal from 'react-modal';
import Calendar from 'react-calendar';
import { useDropzone } from 'react-dropzone';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	Autoformat,
	AutoImage,
	AutoLink,
	Autosave,
	BlockQuote,
	Bold,
	Code,
	CodeBlock,
	Essentials,
	FindAndReplace,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	FullPage,
	GeneralHtmlSupport,
	Heading,
	Highlight,
	HorizontalLine,
	HtmlEmbed,
	ImageBlock,
	ImageInline,
    // eslint-disable-next-line
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageToolbar,
    // eslint-disable-next-line
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	Markdown,
	MediaEmbed,
	Paragraph,
	PasteFromOffice,
	RemoveFormat,
	SelectAll,
	ShowBlocks,
	SimpleUploadAdapter,
	SourceEditing,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Style,
	Subscript,
	Superscript,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	TodoList,
	Underline,
	Undo
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import translations from 'ckeditor5/translations/ru.js';
import api from '/app/frontend/src/api';
import './styles/cked.css';
import { format } from 'date-fns';

const CreateTask = () => {
    Modal.setAppElement('#root');
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const slugboards = localStorage.getItem('slugboards');
    const grouptaskcreate = localStorage.getItem('grouptaskcreate');
    const group_system_status = localStorage.getItem('grouptasksystemstatus');
    const grouptaskidcreate = localStorage.getItem('grouptaskidcreate');
    const related_task_id = localStorage.getItem('relatedtaskid');
    const [rating, setRating] = useState(null);
    const [hover, setHover] = useState(null);
    const [totalStars] = useState(10);
    const [descriptiontask, setDescriptionTask] = useState('');
    const [titletask, setTitleTask] = useState('');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalIsOpenLabel, setIsOpenLabel] = useState(false);
    const [modalIsOpenGetLabel, setIsOpenGetLabel] = useState(false);
    const [modalIsOpenCalendar, setIsOpenCalendar] = useState(false);
    const [performer, setperformer] = useState([]);
    const [label, setLabel] = useState([]);
    const [selectedlabels, setSelectedLabels] = useState([]);
    const [selectedperformers, setSelectedPerformers] = useState([]);
    const [namelabel, setNameLabel] = useState('');
    const [files, setFiles] = useState([]);
    const [filesinfo, setFilesinfo] = useState([]);
    const [deadline, setDeadline] = useState();
    const webcolors = [
        "#000000", "#0000FF", "#008000", "#008080", "#00FF00",
        "#00FFFF", "#800080", "#808000", "#808080", "#C0C0C0",
        "#FF0000", "#FF00FF", "#FFFF00", "#FFFFFF", "#F0F8FF", "#FAEBD7",
        "#00FF7F", "#D8BFD8", "#000080", "#FFFFF0", "#800000", "#FFA500", 
    ];
    const [selectedColor, setSelectedColor] = useState('');
    const [modalIsOpenTaskTemplate, setModalIsOpenTaskTemplate] = useState(false);
    const [templatestasks, settemplatestasks] = useState([]);
    const [filtertemplatestasks, setfiltertemplatestasks] = useState([]);
    const [searchitem, setsearchitem] = useState([]);

    const [value, onChangeCalendar] = useState(new Date());

    const handleUpdateTaskDeadline = (date) => {
        const formattedDate = format(new Date(date), 'yyyy-MM-dd');
        setDeadline(formattedDate);
        closeModal();
    };

    const editorConfig = {
        toolbar: {
			items: [
				'undo',
				'redo',
				'|',
				'sourceEditing',
				'showBlocks',
				'findAndReplace',
				'selectAll',
				'|',
				'heading',
				'style',
				'|',
				'fontSize',
				'fontFamily',
				'fontColor',
				'fontBackgroundColor',
				'|',
				'bold',
				'italic',
				'underline',
				'strikethrough',
				'subscript',
				'superscript',
				'code',
				'removeFormat',
				'|',
				'specialCharacters',
				'horizontalLine',
				'link',
				'insertImage',
				'mediaEmbed',
				'insertTable',
				'highlight',
				'blockQuote',
				'codeBlock',
				'htmlEmbed',
				'|',
				'alignment',
				'|',
				'bulletedList',
				'numberedList',
				'todoList',
				'outdent',
				'indent',
				'|',
				'accessibilityHelp'
			],
			shouldNotGroupWhenFull: true
		},
		plugins: [
			AccessibilityHelp,
			Alignment,
			Autoformat,
			AutoImage,
			AutoLink,
			Autosave,
			BlockQuote,
			Bold,
			Code,
			CodeBlock,
			Essentials,
			FindAndReplace,
			FontBackgroundColor,
			FontColor,
			FontFamily,
			FontSize,
			FullPage,
			GeneralHtmlSupport,
			Heading,
			Highlight,
			HorizontalLine,
			HtmlEmbed,
			ImageBlock,
			ImageInline,
			// ImageInsert,
			ImageInsertViaUrl,
			ImageResize,
			ImageStyle,
			ImageToolbar,
			// ImageUpload,
			Indent,
			IndentBlock,
			Italic,
			Link,
			LinkImage,
			List,
			ListProperties,
			Markdown,
			MediaEmbed,
			Paragraph,
			PasteFromOffice,
			RemoveFormat,
			SelectAll,
			ShowBlocks,
			SimpleUploadAdapter,
			SourceEditing,
			SpecialCharacters,
			SpecialCharactersArrows,
			SpecialCharactersCurrency,
			SpecialCharactersEssentials,
			SpecialCharactersLatin,
			SpecialCharactersMathematical,
			SpecialCharactersText,
			Strikethrough,
			Style,
			Subscript,
			Superscript,
			Table,
			TableCaption,
			TableCellProperties,
			TableColumnResize,
			TableProperties,
			TableToolbar,
			TextTransformation,
			TodoList,
			Underline,
			Undo
		],
		fontFamily: {
			supportAllValues: true
		},
		fontSize: {
			options: [10, 12, 14, 'default', 18, 20, 22],
			supportAllValues: true
		},
		heading: {
			options: [
				{
					model: 'paragraph',
					title: 'Paragraph',
					class: 'ck-heading_paragraph'
				},
				{
					model: 'heading1',
					view: 'h1',
					title: 'Heading 1',
					class: 'ck-heading_heading1'
				},
				{
					model: 'heading2',
					view: 'h2',
					title: 'Heading 2',
					class: 'ck-heading_heading2'
				},
				{
					model: 'heading3',
					view: 'h3',
					title: 'Heading 3',
					class: 'ck-heading_heading3'
				},
				{
					model: 'heading4',
					view: 'h4',
					title: 'Heading 4',
					class: 'ck-heading_heading4'
				},
				{
					model: 'heading5',
					view: 'h5',
					title: 'Heading 5',
					class: 'ck-heading_heading5'
				},
				{
					model: 'heading6',
					view: 'h6',
					title: 'Heading 6',
					class: 'ck-heading_heading6'
				}
			]
		},
		htmlSupport: {
			allow: [
				{
					name: /^.*$/,
					styles: true,
					attributes: true,
					classes: true
				}
			]
		},
		image: {
			toolbar: ['imageTextAlternative', '|', 'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|', 'resizeImage']
		},
		language: 'ru',
		link: {
			addTargetToExternalLinks: true,
			defaultProtocol: 'https://',
			decorators: {
				toggleDownloadable: {
					mode: 'manual',
					label: 'Downloadable',
					attributes: {
						download: 'file'
					}
				}
			}
		},
		list: {
			properties: {
				styles: true,
				startIndex: true,
				reversed: true
			}
		},
		placeholder: 'Type or paste your content here!',
		style: {
			definitions: [
				{
					name: 'Article category',
					element: 'h3',
					classes: ['category']
				},
				{
					name: 'Title',
					element: 'h2',
					classes: ['document-title']
				},
				{
					name: 'Subtitle',
					element: 'h3',
					classes: ['document-subtitle']
				},
				{
					name: 'Info box',
					element: 'p',
					classes: ['info-box']
				},
				{
					name: 'Side quote',
					element: 'blockquote',
					classes: ['side-quote']
				},
				{
					name: 'Marker',
					element: 'span',
					classes: ['marker']
				},
				{
					name: 'Spoiler',
					element: 'span',
					classes: ['spoiler']
				},
				{
					name: 'Code (dark)',
					element: 'pre',
					classes: ['fancy-code', 'fancy-code-dark']
				},
				{
					name: 'Code (bright)',
					element: 'pre',
					classes: ['fancy-code', 'fancy-code-bright']
				}
			]
		},
		table: {
			contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
		},
		translations: [translations],
        // eslint-disable-next-line
        placeholder: "Введите описание задачи"
    };

    useEffect(() => {
        if (grouptaskcreate !== '') {
        } 
        else {
            window.location.href = '/main'
        }
        // eslint-disable-next-line
    }, []);

    function closeModal() {
        setIsOpen(false);
        setIsOpenLabel(false);
        setIsOpenGetLabel(false);
        setIsOpenCalendar(false);
        setModalIsOpenTaskTemplate(false);
    };

    function openModalCalendar() {
        closeModal();
        setIsOpenCalendar(true);
    };

    function openModal() {
        closeModal();
        fetchAllUsers();
        setIsOpen(true);
    };

    function fetchAllUsers(page = 1, allUsers = []) {
        api.get(`${apiUrl}api/identity/users/`, {
            params: { page: page },
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response) => {
            if (response.status === 200) {
                const fetchedUsers = response.data;
                const updatedUsersList = allUsers.concat(fetchedUsers);
                if (response.data.next) {
                    fetchAllUsers(page + 1, updatedUsersList);
                } else {
                    setperformer(updatedUsersList);
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    function openModalLabel() {
        closeModal();
        setIsOpenLabel(true);
    };

    function openModalGetLabel() {
        closeModal();
        getlabel();
        setIsOpenGetLabel(true);
    };

    function getlabel() {
        api.get(`${apiUrl}api/v1/labels/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setLabel(response.data);
            }
        }).catch(error =>{
            console.error(error);
            const status = error.response ? error.response.status : null;
            if (error.response.status === 403)
            {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
        });
    };

    const handleCreateLabel = (event) => {
        event.preventDefault();
        if (label.some(labeli => labeli.name === namelabel)) {
            alert("Метка с таким названием уже существует");
        }
        else {
            api.post(`${apiUrl}api/v1/labels/`, {
                'name': namelabel,
                'color': selectedColor
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 201) {
                    closeModal()
                }
            }).catch(error => {
                console.error(error);
                const status = error.response ? error.response.status: null;
                if (error.response.status === 400){
                    if (error.response.data.name)
                        {
                            alert(`Код ошибки: ${status}, ${error.response.data.name}`, error);
                        }
                    else {
                        alert(`Код ошибки: ${status}, ${error.response.data.color}`, error);
                    }
                }
            });
        }
    };

    const handleChangeNameLabel = (event) => {
        setNameLabel(event.target.value);
    };

    const handleCheckboxChangePerformer = (performer) => {
        if (selectedperformers.includes(performer)) {
            setSelectedPerformers(selectedperformers.filter((selectedPerformer) => selectedPerformer !== performer));
        } else {
            setSelectedPerformers([...selectedperformers, performer]);
        }
    };

    const handleCheckboxChangeLabel = (label) => {
        if (selectedlabels.includes(label)) {
            setSelectedLabels(selectedlabels.filter((selectedLabel) => selectedLabel !== label));
        } else {
            setSelectedLabels([...selectedlabels, label]);
        }
    };

    const handleOpenProfileClick = (user) => {
        window.location.href = "/profile/" + user;
    };

    const handlecreateTask = (event) => {
        event.preventDefault();
        if (titletask === ""){
            alert("Укажите заголовок задачи!");
        }
        else{
            const refreshToken = localStorage.getItem('refreshToken');
            axios.post(`${apiUrl}api/identity/auth/jwt/refresh/`, {
                'refresh': refreshToken
            },{
                headers: {
                    'Authorization': 'Bearer ' + refreshToken
                },
            }).then((response) => {
                const newToken = response.data.access;
                localStorage.setItem('token', response.data.access);
                if (newToken) {
                    const subtasksArray = [];
                    if (related_task_id !== '') {
                        subtasksArray.push(related_task_id);
                    }
                    const filterforperformer = performer.filter((performer) => selectedperformers.includes(performer.username));
                    const performerid = filterforperformer.map(performer => performer.id);
                    api.post(`${apiUrl}api/v1/tasks/`, {
                        'title': titletask,
                        'deadline': deadline,
                        'group': grouptaskidcreate,
                        'priority': rating,
                        'description': descriptiontask,
                        'label': selectedlabels,
                        'performer': performerid,
                        'system_status': group_system_status,
                        'subtasks': subtasksArray,
                    },{
                        headers: {
                            'Authorization': 'Bearer ' + newToken
                        },
                    }
                    ).then((response) => {
                    if (response.status === 201) {
                        localStorage.setItem('relatedtaskid', '')
                        closeModal();
                        setSelectedLabels([]);
                        setSelectedPerformers([]);
                        setTitleTask("");
                        setDeadline("");
                        setRating("");
                        if (response.data.task_id) {
                            if (files.length !== 0) {
                                handleUpload({task_id: response.data.task_id});
                            }
                            else {
                                localStorage.setItem('grouptaskcreate', '');
                                window.location.href = `/boards/${slugboards}/`;
                            }
                        }
                        else{
                            localStorage.setItem('grouptaskcreate', '');
                            window.location.href = `/boards/${slugboards}/`;
                        }
                    }
                    }).catch(error => {
                        console.error(error);
                        const status = error.response ? error.response.status: null;
                        if (error.response.status === 403 && error.response.data.detail === 'NotAllowCreateTaskInCompletedStatus') {
                            alert(`Код ошибки: ${status}, В вашей организации запрещено создавать задачи в статусе выполнено`, error);
                        }
                    }); 
            }})
            .catch(error => {
                console.error(error);
            });
    }};

    const onDropRejected = (rejectedFiles) => {
        rejectedFiles.forEach(file => {
            if (file.errors[0].code === 'file-too-large') {
                alert('Ошибка: Файл должен быть не более 25MB');
            } else {
                alert('Произошла ошибка при загрузке файла: ' + file.errors[0].message);
            }
        });
    };

    const onDrop = useCallback((acceptedFiles) => {
        const updateFiles = [...filesinfo, ...acceptedFiles];
        setFilesinfo(updateFiles);
        setFiles(updateFiles.map((file) => file));
    }, [filesinfo]);

    const {getRootProps, getInputProps,} = useDropzone({onDrop, onDropRejected, maxSize: 26214400, });
    
    function handleUpload(data) {
        if (files){
            const formData = new FormData();
            files.forEach((file) => {
                formData.append('file', file);
            });
            api.post(`${apiUrl}api/upload-file/`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            })
            .then(response => {
                if (response.status === 201) {
                    AddFileToTask({task_id: data.task_id, 
                        file_id: response.data.map(file => file.id)});
                }
            })
            .catch(error => {
                console.error(error);
            });
        }
        else {
            localStorage.setItem('grouptaskcreate', '');
            window.location.href = `/boards/${slugboards}/`;
        }
    };

    function AddFileToTask (data){
        api.patch(`${apiUrl}api/v1/tasks/${data.task_id}/`, {
            'file': data.file_id,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
        ).then((response) => {
        if (response.status === 200) { 

            localStorage.setItem('grouptaskcreate', '');
            window.location.href = `/boards/${slugboards}/`;
        }
        }).catch(error => {
            console.error(error);
        });
        console.log("И файлы добавились");
    };
    
    const removeFile = (file) => () => {
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
        URL.revokeObjectURL(file.preview);
        const newfilesinfo = [...filesinfo];
        newfilesinfo.splice(newfilesinfo.indexOf(file), 1);
        setFilesinfo(newfilesinfo);
    };

    const handleTitleTaskChange = (event) => {
        setTitleTask(event.target.value);
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setDescriptionTask(data);
    };

    const handleOpenTemplateTask = () => {
        api.get(`${apiUrl}api/v1/template-task/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                settemplatestasks(response.data);
                setfiltertemplatestasks(response.data);
                setModalIsOpenTaskTemplate(true);
            }
        }).catch(error =>{
            console.error(error);
            const status = error.response ? error.response.status: null;
            if (error.response.status === 403)
            {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
            // alert(`Ошибка: ${error.code}, Код ошибки: ${status}`, error)
        });
    };

    const handleSearch = () => {
        if (searchitem.length >= 2) {
            console.log(searchitem);
            const regex = new RegExp(searchitem, "i");
            const filtered = filtertemplatestasks.filter((task) => regex.test(task.title));
            setfiltertemplatestasks(filtered);
        } 
        else if (searchitem.length < 2) {
            setfiltertemplatestasks(templatestasks);
        }
    };

    const handleCreateTaskFromTemplate = (data) => {
        getlabel();
        fetchAllUsers();
        setTitleTask(data.title);
        setDescriptionTask(data.description);
        setRating(data.priority);
        if (data.label) {
            setSelectedLabels(data.label.split(',').map(label => parseInt(label)));
        }
        setSelectedPerformers(data.performer.split(','));
    };

    document.title = "Создание задачи - ТвояЗадача";

    return (
        <div 
            className='detail-task-page' 
        >
            <Modal
                className="ModalSubtasks"
                isOpen={modalIsOpenTaskTemplate}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
                >
                    <div id="div-modal-subtasks">
                        <div id="div-for-close-and-label-modal">
                            <label id="modal-label-subtasks">Создать задачу</label>
                            <input
                                id='search-input-subtasks'
                                placeholder='Введите текст, чтобы найти шаблон задачи'
                                onChange={(event) => setsearchitem(event.target.value)}
                            >
                            </input>
                            <button id="search-modal-subtasks" onClick={handleSearch}>Поиск</button>
                            <button id="close-modal-checklist" onClick={closeModal}>X</button>
                        </div>
                        <div id="scroll-subtask">
                        {filtertemplatestasks.map((tt, ttindex) => (
                            <div id="div-with-subtasks-and-btn">
                                <button id="open-this-tasks"
                                    onClick={() => handleCreateTaskFromTemplate(tt)}
                                >
                                    {tt.title}
                                </button>
                            </div>
                        ))}
                        </div>
                    </div>
            </Modal>
            <Modal
                className="ModalforPerformer"
                isOpen={modalIsOpenGetLabel}
                onRequestClose={closeModal}
                overlayClassName="Overlayforperformer"
            >
                <div id="rightwindow">
                    <button id="CloseModalTask" onClick={closeModal}>X</button>
                    <label id="performerlabel">Метка:</label>
                    <div id="updivright">
                        {label.map((labeli, labelindex) => (
                            <div id="divupforscroll" key={labelindex}>
                                    <div id="div-with-label" key={labelindex}>
                                        <circle style={{
                                            background: labeli.color, 
                                            height: "25px", 
                                            width: "25px",
                                            borderRadius: "5px",
                                            marginLeft: "15px", 
                                            marginBottom: "10px",
                                            boxShadow: "0px 0px 50px 0px " + labeli.color,
                                        }}>
                                        </circle>
                                        <label id="namelabels"
                                            key={labelindex}   
                                            style={{ background: selectedlabels.includes(labeli.id) ? 'white' : 'none',
                                                    color: selectedlabels.includes(labeli.id) ? 'rgb(63, 127, 232)' : 'black',
                                                    border: selectedlabels.includes(labeli.id) ? '1px solid rgb(63, 127, 232)' : '1px solid black',             
                                                    }}
                                            >
                                            <input
                                                type="checkbox"
                                                key={labelindex}
                                                name="performers"
                                                onChange={() => handleCheckboxChangeLabel(labeli.id)}
                                                checked={selectedlabels.includes(labeli.id)}
                                            />
                                                {labeli.name.slice(0, 14)}
                                        </label>
                                    </div>
                            </div>
                        ))}
                    </div>
                    <div id="gorizontal-line-for-split-label"/>
                    <button id="open-modal-create-label"
                        onClick={openModalLabel}
                    >
                        Создать
                    </button>
                </div>
            </Modal>
            <Modal
                className="ModalforPerformer"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                overlayClassName="Overlayforperformer"
            >
                <div id="rightwindow">
                    <button id="CloseModalTask" onClick={closeModal}>X</button>
                    <label id="performerlabel">Исполнитель:</label>
                    <div id="updivright">
                        {performer.map((performer, index) => (
                            <div id="divupforscroll" key={index}>
                                {performer.image ? 
                                    <img id="photoprofileforperformer"
                                        src={performer.image}
                                        alt=''
                                    >
                                    </img>:
                                    <img id="photoprofileforperformer"
                                        src={apiUrlFrontend + "icon_man.png"}
                                        alt=''
                                    >
                                    </img>
                                }
                                <label id="namelabels"
                                    key={index}
                                    style={{ background: selectedperformers.includes(performer.username) ? 'white' : 'none',
                                             color: selectedperformers.includes(performer.username) ? 'rgb(63, 127, 232)' : 'black',
                                             border: selectedperformers.includes(performer.username) ? '1px solid rgb(63, 127, 232)' : '1px solid black',             
                                            }}
                                >
                                    <input
                                        type="checkbox"
                                        key={index}
                                        name="performers"
                                        onChange={() => handleCheckboxChangePerformer(performer.username)}
                                        checked={selectedperformers.includes(performer.username)}
                                    />
                                    {performer.formated_fio ?
                                        performer.formated_fio.slice(0, 15):
                                        performer.username.slice(0, 15)
                                    }
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
            <Modal
                className="ModalforPerformer"
                isOpen={modalIsOpenLabel}
                onRequestClose={closeModal}
                overlayClassName="Overlayforperformer"
            >
                <div id="rightwindow">
                    <button id="CloseModalTask" onClick={closeModal}>X</button>
                    <label id="performerlabel">Создание метки:</label>
                    <label id="hint-for-label">Название</label>
                    <input id="name-label"
                        onChange={handleChangeNameLabel}
                    >
                    </input>
                    <label id="hint-for-label">Цвет</label>
                    <div style={{ width: '240px', display: 'flex', flexWrap: 'wrap', borderRadius: "10px", background: "rgba(196, 196, 196, 0.54)", marginLeft: "5px" }}>
                        {webcolors.map((color, index) => (
                            <div key={index} style={{ backgroundColor: color, width: selectedColor === color ? '90px': '50px', height: selectedColor === color ? '70px': '30px', margin: '5px', cursor: 'pointer', borderRadius: '10px'}} 
                            onClick={() => setSelectedColor(color)}>  
                            </div>
                        ))}
                    </div>
                    <button id="create-label"
                        onClick={handleCreateLabel}
                    >
                        Создать
                    </button>
                </div>
            </Modal>
            <div id="div-for-all-create" 
            style={{ overflowY: 'auto', whiteSpace: 'nowrap'}} >
                <div id="for-task-history-create-left">
                    <textarea
                        style={{marginBottom: '15px'}}
                        type="text"
                        id="titlefortasks"
                        placeholder='Введите заголовок задачи'
                        required
                        maxLength={250}
                        value={titletask}
                        onChange={handleTitleTaskChange}
                    >
                    </textarea>
                    <div id="div-for-editor">
                        <CKEditor
                            editor={ ClassicEditor }
                            disabled={false}
                            data={descriptiontask}
                            config={ 
                                editorConfig
                            }
                            onChange={ ( event, editor ) => {
                                handleEditorChange(event, editor)
                            }}
                                    // onBlur={ ( event, editor ) => {
                                    //     console.log( 'Blur.', editor );
                                    // } }
                                    // onFocus={ ( event, editor ) => {
                                    //     console.log( 'Focus.', editor );
                                    // } }
                        />
                    </div>
                        <div {...getRootProps()}>
                            <input id="place-file-here" {...getInputProps()}/>
                            <div
                                id="place-file-here"
                            >
                                Максимальный размер файла 25MB
                            </div>
                        </div>
                        {filesinfo.map((file) => (
                            <div id="file-info-create" key={file.name}>
                                {file.name} 
                                <button id="delete-file" onClick={removeFile(file)}>X</button>
                            </div>
                        ))}
                </div>
                <div id="div-for-left-and-right">
                    <div id="for-task-history-create-right">
                        <div id="div-with-status-and-add">
                            <label id="label-div-right-title">Состояние:</label>
                            <button id="change-task-group">{grouptaskcreate === null ? "" : grouptaskcreate.slice(0, 12)}</button>
                        </div>
                        <div id="gorizontal-line-for-right-div"/>
                        <div id="div-perfromer-add">
                            <label id="label-div-right-title">Исполнитель:</label>
                            <button id="add-performer-to-task"
                                onClick={() => openModal()}
                            >
                                Добавить
                            </button>
                        </div>
                        {performer.filter(pf => selectedperformers.includes(pf.username)).map((k, kindex) => (
                            <div id="div-with-perfromer" key={kindex}>
                                <img id="image-performer"
                                    src={k.image}
                                    alt=''
                                />
                                {k.formated_fio ?
                                <button id="open-profile-performer"
                                    onClick={() => handleOpenProfileClick(k.username)}
                                >
                                    {k.formated_fio.slice(0, 24)}
                                </button>:
                                <button id="open-profile-performer"
                                    onClick={() => handleOpenProfileClick(k.username)}
                                >
                                    {k.username.slice(0, 24)}
                                </button>
                                }
                            </div>
                        ))}
                        <div id="gorizontal-line-for-right-div"/>
                        <div id="div-label-add">
                            <label id="label-div-right-title">Метка:</label>
                            <button id="add-label-to-task"
                                onClick={openModalGetLabel}
                            >
                                Добавить
                            </button>
                        </div>
                        {label.filter((lb) => selectedlabels.includes(lb.id)).map((task, taskindex) => (
                            <div id="div-with-label" key={taskindex}>
                                <circle style={{
                                    background: task.color, 
                                    height: "25px", 
                                    width: "25px",
                                    borderRadius: "5px",
                                    marginLeft: "15px", 
                                    marginBottom: "10px",
                                    boxShadow: "0px 0px 50px 0px " + task.color,
                                }}>
                                </circle>
                                <label id="label-name">{task.name}</label>
                            </div>
                        ))}
                        <div id="gorizontal-line-for-right-div"/>
                        <div id="div-label-add">
                            <label id="label-div-right-title">Приоритет:</label>
                        </div>
                        <div id="div-with-priority">
                            <div className="App">
                                    {[...Array(totalStars)].map((_, index) => {
                                        const currentRating = index + 1;
                                        return (
                                            <label key={index}>
                                                <input
                                                    key={currentRating}
                                                    type="radio"
                                                    name="rating"
                                                    value={currentRating}
                                                    onChange={() => setRating(currentRating)}
                                                />
                                                <div className="rating-item"
                                                    style={{
                                                        color: currentRating <= (hover || rating) ? "white" : "rgba(5, 7, 76, 0.54)",
                                                        background: currentRating <= (hover || rating) ? "rgba(5, 7, 76, 0.54)" : "rgba(5, 7, 76, 0.54)"
                                                    }}
                                                    onMouseEnter={() => setHover(currentRating)}
                                                    onMouseLeave={() => setHover(null)}
                                                >
                                                    {currentRating}
                                                </div>
                                            </label>
                                        );
                                    })}
                            </div>
                        </div>
                        <div id="div-with-deadline">
                            <label id="label-div-right-title">Срок сдачи:</label>
                            <button id="add-deadline-to-task"
                                onClick={openModalCalendar}
                            >
                                {deadline !== "" ? deadline : "Добавить"}
                            </button>
                        </div>
                        <Modal
                            className="ModalforPerformer"
                            isOpen={modalIsOpenCalendar}
                            onRequestClose={closeModal}
                            overlayClassName="Overlayforperformer"
                            id="react-calendar"
                        >   
                            <div id="div-calendar">
                                <button id="CloseModalTask" onClick={closeModal}>X</button>
                                <Calendar onChange={(date) => {
                                    onChangeCalendar(date);
                                    handleUpdateTaskDeadline(date);
                                }} value={value} />
                            </div>
                        </Modal>
                        <button
                            id="confirmtask-create"
                            onClick={handlecreateTask}
                        >
                            Создать
                        </button>
                        <button
                            id="open-modal-create-template-task"
                            onClick={handleOpenTemplateTask}
                        >
                            Создать задачу из шаблона
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateTask;