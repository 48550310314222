import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import HelloPage from './pages/HelloPage';
import Main from './pages/MainPage';
import DetailBoard from './pages/DetailBoardPage'
import Profile from './pages/ProfilePage';
import AboutMe from './pages/pages_for_footer/AboutMePage'
import CreateAppeal from './pages/pages_for_footer/CreateAppealPage'
import PrivacyPolicy from './pages/pages_for_footer/PrivacyPolicyPage'
import DevelopersInfo from './pages/pages_for_header/DevelopersInfoPage'
import PricesInfo from './pages/pages_for_header/PricesInfoPage'
import SolutionsInfo from './pages/pages_for_header/SolutionsInfoPage'
import TasksList from './pages/TasksListPage';
import DetailTask from './pages/DetailTaskPage';
import CreateTask from './pages/CreateTaskPage';
import Settings from './pages/SettingsPage';
import ChangePassword from './pages/ChangePassword';
import ChoiceOrganization from './pages/ChoiceOrganizationPage';
import Registration from './pages/RegistrationPage';
import InfoMain from './pages/InfoMainPage';
import InviteOrganization from './pages/InviteOrganization';

function NoMatch() {
  return (
    <div style={{ padding: 200, textAlign: 'center' }}>
      <h2>404: Page Not Found</h2>
      <p>Такой страницы нет, куда ты тыкаешь!</p>
    </div>
  );
}

function App() {
  const [confirmAuthMobile, setConfirmAuthMobile] = useState(false);
  const authmobile = localStorage.getItem('authmobile');

  function isMobileDevice() {
    if (confirmAuthMobile === false && authmobile !== 'false') {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    else {
      if (authmobile !== 'false') {
        localStorage.setItem('authmobile', 'false');
      }
      return false;
    }
  };

  return (
    <Router>
      <div className="App" style={{display: "flex", width: "100%", height: "100%",}}>
      {isMobileDevice() ? 
        <div id="not-adaptive-device">
          В данный момент адаптация системы под мобильные устройства находится в разработке. Вы хотите продолжить?
          <button id='confirm-not-adaptive-device'
            onClick={() => setConfirmAuthMobile(!confirmAuthMobile)}
          >
            Да
          </button>
        </div>:
        <Routes>
          <Route exact path="/" element={<HelloPage/>}/>
          <Route path="/login" element={<LoginPage/>}/>
          <Route exact path="/main" element={<Main/>}/>
          <Route path="/developers-info" element={<DevelopersInfo/>}/>
          <Route path="/prices-info" element={<PricesInfo/>}/>
          <Route path="/solutions-info" element={<SolutionsInfo/>}/>
          <Route path="/about" element={<AboutMe/>}/>
          <Route exact path="/create-appeal" element={<CreateAppeal/>}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="*" element={<NoMatch/>}/>
          <Route path="/boards/:slug" element={<DetailBoard/>}/>
          <Route path="/profile/:slug" element={<Profile/>}/>
          <Route path="/tasks/list/" element={<TasksList/>}/>
          <Route path="/task/:task_pk" element={<DetailTask/>}/>
          <Route exact path="/create-task" element={<CreateTask/>}/>
          <Route path="/settings" element={<Settings/>}/>
          <Route path="/change-password/:slug/:slug" element={<ChangePassword/>}/>
          <Route path="/choice-organization" element={<ChoiceOrganization/>}/>
          <Route path="/registration" element={<Registration/>}/>
          <Route exact path="main-info" element={<InfoMain/>}/>
          <Route path="/invite-organization/:int/:slug/" element={<InviteOrganization/>}/>
        </Routes> 
    }
    </div>
    </Router>
  );
}

export default App;
